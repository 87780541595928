/* -------------------------------------------------------------------------- */
/*                              Config                                        */
/* -------------------------------------------------------------------------- */
const CONFIG = {
  isNavbarVerticalCollapsed: false,
  theme: "light",
  isRTL: false,
  isFluid: true,
  navbarStyle: "inverted",
  navbarPosition: "vertical",
};


Object.keys(CONFIG).forEach((key) => {

  //Remove config memory in localstorage
  /* if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, CONFIG[key]);
  } */

  localStorage.setItem(key, CONFIG[key]);
});

if (!!JSON.parse(localStorage.getItem("isNavbarVerticalCollapsed"))) {
  document.documentElement.classList.add("navbar-vertical-collapsed");
}

if (localStorage.getItem("theme") === "dark") {
  document.documentElement.setAttribute("data-bs-theme", "dark");
}

export default CONFIG;
